.NewBooking {
    padding-top: 75px;
    background-color: rgb(237, 225, 181);
    padding-bottom: 75px;
}

.NewBooking .container {
    width: 100%;
    max-width: 800px;
}

.NewBooking h2 {
    margin-top: 20px;
    text-align: center;
    color: rgb(1, 51, 40);
}

.NewBooking p {
    margin-top: 10px;
    text-align: center;
    color: rgb(1, 51, 40);
}

.NewBooking .form-control-input,
.NewBooking .form-control-textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid rgb(1, 51, 40);
    border-radius: 4px;
    background-color: rgb(237, 225, 181);
    color: black;
    font-weight: 200;
}

.NewBooking .date-input{
    height: 50px;
    width: 100%;
    border: 1px solid rgb(1, 51, 40);
    border-radius: 4px;
    background-color: rgb(237, 225, 181);
    padding: 10px;
    margin-bottom: 20px;
}

.NewBooking .custom-date-input input {
    width: 730px;
    border: none;
    background-color: transparent;
    color: black;
    font-weight: 200;
    padding: 0;
    padding-left: 10px;
}



.NewBooking .custom-date-input i {
    color: rgb(1, 51, 40);
    cursor: pointer;
}

.NewBooking .form-control-submit-button {
    width: 200px;
    background-color: rgb(1, 51, 40);
    color: #fff;
    padding: 10px 20px;
    border: 2px solid rgb(1, 51, 40);
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s;
}

.NewBooking .form-group {
    position: relative;
}

.NewBooking .form-group label {
    position: absolute;
    top: -12px;
    left: 10px;
    background-color: rgb(237, 225, 181);
    padding: 0 5px;
    color: rgb(1, 51, 40);
    font-size: 0.875rem;
}

.NewBooking .form-control-input:focus,
.NewBooking .form-control-textarea:focus {
    border-color: rgb(1, 51, 40);
    outline: none;
}

.NewBooking .form-control-submit-button:hover {
    background-color: rgb(237, 225, 181);
    color: rgb(1, 51, 40);
}

.NewBooking .summary {
    padding: 15px;
    border: 1px solid rgb(1, 51, 40);
    border-radius: 4px;
}

.NewBooking .summary .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.NewBooking .summary .detail h5 {
    margin: 0;
    color: rgb(1, 51, 40);
    font-size: 1rem;
}

.NewBooking .summary .amount h5 {
    margin: 0;
    color: rgb(1, 51, 40);
    font-weight: 600;
    font-size: 1.2rem;
    border-bottom: 3px solid rgb(1, 51, 40);
}

.NewBooking .summary .amount p {
    margin: 0;
    color: rgb(1, 51, 40);
    font-size: 1rem;
    text-align: right;
}

.NewBooking .submit {
    width: 100%;
    text-align: center;
}

@media (max-width: 810px)  {
    .NewBooking {
        padding-top: 100px;
        background-color: rgb(237, 225, 181);
        padding-bottom: 75px;
    }

    .NewBooking .custom-date-input input {
        width: 600px;
    }
}

.NewBooking .loading {
    position: fixed;
    top: 50%;
    left: 45%;
    transform: translate(-50%, -50%);
    border: 16px solid #ffffff; /* Light grey */
    border-top: 16px solid rgb(1, 51, 40); /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    z-index: 1000;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (max-width: 680px)  {
    .NewBooking .custom-date-input input {
        width: 500px;
    }
}

@media (max-width: 550px)  {
    .NewBooking .custom-date-input input {
        width: 340px;
    }
}

@media (max-width: 400px)  {
    .NewBooking .custom-date-input input {
        width: 260px;
    }
}