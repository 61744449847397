.AdminMainpage {
    padding: 20px;
    background-color: #f3f2ef;
    margin-top: 70px;
    font-family: Arial, sans-serif;
  }
  
  .AdminMainpage h2 {
    color: #333;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .AdminMainpage .search-input {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    font-size: 16px;
  }
  
  .AdminMainpage .form-control-submit-button {
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-left: 10px;
    transition: background-color 0.3s;
  }
  
  .AdminMainpage .form-control-submit-button:hover {
    background-color: #45a049;
  }
  
  .AdminMainpage .booking-list {
    max-width: 850px;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .AdminMainpage .booking-card {
    background-color: #ffffff;
    border-radius: 10px;
    margin: 10px 0;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
  }
  
  .AdminMainpage .booking-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .AdminMainpage .booking-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-left: 6px solid transparent;
    transition: border-color 0.3s;
  }
  
  .AdminMainpage .booking-summary:hover {
    border-color: #ffc107;
  }
  
  .AdminMainpage .booking-info p {
    margin: 5px 0;
    color: #555;
    font-weight: 500;
  }
  
  .AdminMainpage .booking-details {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #ddd;
    font-size: 14px;
    color: #777;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 15px;
  }
  
  .AdminMainpage .booking-details p {
    margin: 5px 0;
  }
  
  .AdminMainpage .checkin-today {
    color: #ffc107;
    font-weight: bold;
  }
  
  .AdminMainpage .checkin-upcoming {
    color: #4caf50;
    font-weight: bold;
  }
  
  .AdminMainpage .checkin-past {
    color: #e53935;
    font-weight: bold;
  }
  
