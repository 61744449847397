.Gallery {
    width: 100%;
    padding-top: 70px;
    background-color: rgb(237, 225, 181);
    padding-bottom: 40px;
}

.Gallery .tittle {
    font-size: 40px;
    color: rgb(51, 51, 51);
    font-weight: 500;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
}

.Gallery .galleryall {
    width: 100%;
    background-color: rgb(237, 225, 181);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 10px;
    justify-content: center;
}

.Gallery .galleryall .item-img {
    position: relative;
    width: 100%;
    padding-top: 80%; /* 1:1.3 aspect ratio */
}

.Gallery .galleryall .item-img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 0;
    border-radius: 0.8rem;
    transition: 0.5s;
}

.Gallery .galleryall .item-img img:hover {
    -moz-transform: scale(1.015);
	-webkit-transform: scale(1.015);
	transform: scale(1.015);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 0;
    border-radius: 0.8rem;
    transition: 0.3s;
}

/* Modal styles */
.Gallery .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
}

.Gallery .modal-content {
    width: auto;
    max-height: 90%;
    margin: auto;
    display: block;
}

.Gallery .close {
    position: absolute;
    top: 20px;
    right: 35px;
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
    cursor: pointer;
}

.Gallery .close:hover,
.Gallery .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .page-number {
    margin: 10px 5px;
    width: 30px;
    height: 30px;
    font-size: 16px;
    border-radius: 50%;
    border: 2px solid rgb(1, 51, 40);
    background-color: rgb(237, 225, 181);
    cursor: pointer;
    color: rgb(1, 51, 40);
    font-weight: 400;
  }
  
  .page-number.active {
    background-color: rgb(1, 51, 40);
    color: rgb(237, 225, 181)
  }
  
  .page-number:hover {
    background-color: rgb(1, 51, 40);
  }

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .Gallery .galleryall {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
}
