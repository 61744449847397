.video-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Make it fullscreen */
  overflow: hidden; /* Hide overflow */
}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}

.content {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  z-index: 1; /* Ensure content appears above video */
}

.content-text {
  max-width: 600px; /* Adjust based on design */
  margin: 0 auto;
}

.content h1 {
  font-size: 2.5rem;
  margin-bottom: 10px; /* Adjust spacing */
}

#text1 {
  font-size: 3.5rem;
}

#text2 {
  color: rgb(231, 189, 0);
}

.content h2 {
  font-size: 1.5rem;
  margin-bottom: 20px; /* Adjust spacing */
}

.btn {
  display: inline-block;
  padding: 12px 24px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-top: 20px; /* Adjust spacing */
}

.btn:hover {
  background-color: rgba(255, 255, 255, 0.4);
}
