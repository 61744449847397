/* components/ScrollToTopButton.css */
.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px; /* Changed from left to right */
    cursor: pointer;
    z-index: 1000;
  }
  
  .scroll-to-top-icon {
    font-size: 2rem;
    color: #ffffff;
    background-color: #000000;
    border-radius: 50%;
    padding: 10px;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .scroll-to-top-icon:hover {
    background-color: #555555;
    transform: translateY(-2px);
  }
  