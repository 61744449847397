/* Booking.css */
.bookingpage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: rgb(237, 225, 181);
    padding: 20px;
}

.bookingpage .success-icon {
    margin-bottom: 20px;
}

.bookingpage .error-icon{
    margin-bottom: 20px;
}

.bookingpage h2 {
    color: #333;
    margin-bottom: 10px;
}

.bookingpage p {
    color: #666;
    margin-bottom: 30px;
}

.bookingpage .booking-details {
    background-color: rgb(237, 225, 181);
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    width: 100%;
    border: 2px solid rgb(1, 51, 40);
    max-width: 400px;
}

.bookingpage .detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.bookingpage button {
    width: 200px;
    background-color: rgb(1, 51, 40);
    color: #fff;
    padding: 10px 20px;
    border: 2px solid rgb(1, 51, 40);
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s;
}

.bookingpage button:hover {
    background-color: rgb(237, 225, 181);
    color: rgb(1, 51, 40);
}

.loading_2 {
    position: fixed;
    top: 50%;
    left: 45%;
    transform: translate(-50%, -50%);
    border: 16px solid #ffffff; /* Light grey */
    border-top: 16px solid rgb(1, 51, 40); /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    z-index: 1000;
}

@media (max-width: 768px) {
    .bookingpage {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        background-color: rgb(237, 225, 181);
        padding-top: 80px;
    }

    .loading_2 {
        position: fixed;
        top: 45%;
        left: 39%;
        transform: translate(-50%, -50%);
        border: 16px solid #ffffff; /* Light grey */
        border-top: 16px solid rgb(1, 51, 40); /* Blue */
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 2s linear infinite;
        z-index: 1000;
    }
}

