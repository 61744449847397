.img-slider {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px; 
    overflow: hidden;
    background-color: rgb(237, 225, 181);
    padding: 50px;
  }
  
  .slide {
    position: relative;
    overflow: hidden;
  }
  
  .slide img {
    width: 100%;
    height: 300px;
    object-fit: cover; 
    border-radius: 10px;
    transition: 0.5s;
  }

  .slide img:hover {
    -moz-transform: scale(1.15);
	-webkit-transform: scale(1.15);
	transform: scale(1.15);
    border-radius: 10px;
  }
  
  @media (max-width: 1024px) {
    .img-slider {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .img-slider {
      grid-template-columns: repeat(1, 1fr);
      padding: 50px 10px;
    }
}
  