.donate {
    padding-top: 4rem;
    padding-bottom: 4rem;
    background-color: rgb(1, 51, 40);
    color: white;
}

.donate .text-container h2 {
    margin-bottom: 1.375rem;
}

.donate .donateform {
    width: 100%;
    padding: 0px 20px;
    border-radius: 0.375rem;
}

.donate .btnchoose {
    min-width: 300px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.donate .btnchoose .btn {
    width: 100%;
    background-color: rgb(1, 51, 40);
    color: white;
    border: 1px solid white;
    padding: 0.75rem 1.5rem;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.donate .btnchoose .btn:hover {
    background-color: rgb(255, 255, 255);
    color: rgb(1, 51, 40);
}

.donate .btnchoose .btn.selected {
    background-color: rgb(255, 255, 255);
    color: rgb(1, 51, 40);
}

.donate .btnamount {
    min-width: 300px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
}

.donate .btnamount .btn {
    width: 100%;
    background-color: rgb(1, 51, 40);
    color: white;
    border: 1px solid white;
    padding: 0.75rem 1.5rem;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.donate .btnamount .btn:hover {
    background-color: rgb(255, 255, 255);
    color: rgb(1, 51, 40);
}

.donate .btnamount .btn.selected {
    background-color: rgb(255, 255, 255);
    color: rgb(1, 51, 40);
}

.donate .paymentlogo img {
    width: 48px;
    margin-right: 10px;
}

.donate .payment {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 300px;
    height: auto;
    border: 1px solid white;
    border-radius: 0.375rem;
    transition: 2s;
    padding: 0;
}

.donate .payment p {
    color: rgb(255, 255, 255);
    margin: 0;
}

/* QR code */
.payment .qr-payment {
    width: 100%;
    margin: 20px;
    background-color: rgb(255, 255, 255);
    border-radius: 0.375rem;
}

.payment .qr-payment .box{
    text-align: center;
}

.payment .qr-payment .logo-promtpay img{
    width: 60%;
    margin-top: 20px;
}

.payment .qr-payment .qrcode img{
    width: 60%;
}

.payment .qr-payment p {
    text-align: center;
    color: black;
    margin-bottom: 20px;
}


/* bavk */
.payment .bank-payment {
    width: 100%;
    padding: 20px;
}

.bank-payment .bank-item {
    width: 100%;
    
}

.bank-payment .bank-item .bank {
    border: 1px solid white;
    border-radius: 0.375rem;
    padding: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: 0.8fr 1.4fr;
    align-items: center; /* Center items vertically */
}


.bank-payment .bank-item .bank .detail p{
    font-size: 17px;
}

.bank-payment .bank-item .bank .detail span{
    font-size: 17px;
    font-weight: 600;
    color: rgb(255, 200, 0);
}

.bank-payment .bank-item img{
    min-width: 70px;
    width: 80%;
    border-radius: 20px;
}

.bank-payment .bank-item .amount{
    width: 100%;
    border: 1px solid white;
    border-radius: 0.375rem;
    margin-top: 10px;
}


.bank-payment .bank-item .amount p {
    text-align: center;
    font-size: 15px;
    padding: 3px 0px;
}

@media (max-width: 1200px) {
    .donate .btnchoose {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
    }
    
    .donate .btnchoose .btn {
        font-size: 14px;
        width: 100%;
        background-color: rgb(1, 51, 40);
        color: white;
        border: 1px solid white;
        padding: 0.3rem 0rem;
        border-radius: 0.375rem;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }
    
    .donate .btnchoose .btn:hover {
        background-color: rgb(255, 255, 255);
        color: rgb(1, 51, 40);
    }
    
    .donate .btnamount {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 10px;
    }
    
    .donate .btnamount .btn {
        font-size: 14px;
        width: 100%;
        background-color: rgb(1, 51, 40);
        color: white;
        border: 1px solid white;
        padding: 0.3rem 0rem;
        border-radius: 0.375rem;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }
}
